<template>
  <footer class="app-footer__wrapper">
    <div class="app-footer__content">
      <Logo
        :size="'sm'"
        :color="'gradient'"
        class="app-footer__logo">
      </Logo>

      <ul class="app-footer__bua-links">
        <li
          v-for="(buaLink, index) in buaLinks"
          :key="`bua-link-${index}`"
          :class="[
            'app-footer__list-item',
            { 'app-footer__list-item--title': buaLink.isTitle },
          ]">
          <component
            :is="buaLink.isTitle ? 'span' : 'a'"
            :href="buaLink.link"
            target="_blank">
            {{ buaLink.label }}
          </component>
        </li>
      </ul>

      <ul class="app-footer__activity-links">
        <li
          v-for="(activityLink, index) in activityLinks"
          :key="`activity-link-${index}`"
          :class="[
            'app-footer__list-item',
            { 'app-footer__list-item--title': activityLink.isTitle },
          ]">
          <component
            :is="activityLink.isTitle ? 'span' : 'a'"
            :href="activityLink.link">
            {{ $t(activityLink.label) }}
          </component>
        </li>
      </ul>

      <ul class="app-footer__help-links">
        <li
          v-for="(helpLink, index) in helpLinks"
          :key="`activity-link-${index}`"
          :class="[
            'app-footer__list-item',
            { 'app-footer__list-item--title': helpLink.isTitle },
          ]">
          <component
            :is="helpLink.isTitle ? 'span' : 'a'"
            :href="helpLink.link"
            target="_blank">
            {{ $t(helpLink.label) }}
          </component>
        </li>
      </ul>

      <hr class="app-footer__separator">

      <Paragraph class="app-footer__trademark">
        © 2021 Bookunartiste, Inc.
      </Paragraph>

      <ul class="app-footer__legal-links">
        <li
          v-for="(legalLink, index) in legalLinks"
          :key="`legal-link-${index}`"
          class="app-footer__legal-links-item">
          <router-link :to="legalLink.link">
            {{ legalLink.label }}
          </router-link>
        </li>
      </ul>

      <Heading
        :level="'h5'"
        :tag="'span'"
        class="app-footer__locale">
        <Icon
          :variant="'world-outline'"
          class="mr-sm">
        </Icon>
        {{ $t('common.french') }}
      </Heading>

      <ul class="app-footer__socials-list">
        <li
          v-for="(icon, index) in icons"
          :key="`icon-${index}`"
          class="app-footer__socials-item">
          <a
            :href="icon.link"
            target="_blank">
            <Icon :variant="icon.variant"></Icon>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>

import Logo                 from '../../atoms/Logo/a-Logo.vue';
import Icon                 from '../../atoms/Icon/a-Icon.vue';
import Heading              from '../../atoms/Heading/a-Heading.vue';
import Paragraph            from '../../atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'm-AppFooter',
  components: {
    Logo,
    Icon,
    Heading,
    Paragraph,
  },
  computed: {
    buaLinks() {
      return [
        {
          label: 'Book un Artiste',
          isTitle: true,
          link: '',
        },
        {
          label: this.$t('footer.home'),
          link: '/',
        },
        {
          label: this.$t('footer.becomeMember'),
          link: '/artist/signup',
        },
      ];
    },
    activityLinks() {
      return [
        {
          label: this.$t('common.artist'),
          link: '',
          isTitle: true,
        },
        {
          label: this.$t('footer.subscribeInfo'),
          link: '/artist/faq#signup',
        },
        {
          label: this.$t('footer.myPricingInfo'),
          link: '/artist/faq#pricing',
        },
        {
          label: this.$t('footer.myAgendaInfo'),
          link: '/artist/faq#availabilities',
        },
        {
          label: this.$t('footer.publishFaq'),
          link: '/artist/faq#profile',
        },
      ];
    },
    helpLinks() {
      return [
        {
          label: this.$t('footer.help'),
          link: '',
          isTitle: true
        },
        {
          label: this.$t('footer.faq'),
          link: '/artist/faq',
        },
        {
          label: this.$t('footer.contact'),
          link: 'mailto:contact@bookunartiste.com',
        },
        {
          label: this.$t('footer.privacy'),
          link: '/terms',
        },
        {
          label: this.$t('footer.cookies'),
          link: '/terms',
        },
        {
          label: this.$t('footer.termsAndConditions'),
          link: '/terms',
        },
      ];
    },
    legalLinks() {
      return [
        {
          label: this.$t('footer.privacyInfo'),
          link: '/terms',
        },
        {
          label: this.$t('footer.termsInfo'),
          link: '/terms',
        },
      ];
    },
    icons() {
      return [
        {
          variant: 'facebook-outline',
          link: 'https://www.facebook.com/Book-un-artiste-107075977691246/',
        },
        {
          variant: 'instagram-outline',
          link: 'https://www.instagram.com/bookunartiste/',
        },
        {
          variant: 'youtube-outline',
          link: 'https://www.youtube.com/channel/UCEgwoBdwyoQHYox9cnGoMyA/',
        },
      ];
    },
  },
}

</script>

<style lang="scss">

.app-footer {
  &__wrapper {
    position: relative;
    background-color: var(--color-white);
    border-top: 1px solid var(--color-grey-empty);
    font-family: var(--font-stack-secondary);

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: var(--color-gradient-main);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
    min-height: 225px;
    margin: 0 auto;
    padding: var(--space-xl) var(--space-md);
    grid-gap: var(--space-lg);
    padding-bottom: var(--space-lg);
    grid-template-areas:
      "logo logo"
      "bua-links bua-links"
      "activity-links activity-links"
      "help-links help-links"
      "separator separator"
      "socials socials"
      "locale locale"
      "legal-links legal-links"
      "trademark trademark"
    ;

    @media screen and ($desktop) {
      grid-template-columns: min-content repeat(5, auto);
      grid-template-rows: 1fr min-content;
      grid-column-gap: var(--space-xl);
      align-items: flex-start;
      grid-template-areas:
        "logo bua-links bua-links activity-links activity-links help-links"
        "separator separator separator separator separator separator"
        "trademark trademark legal-links legal-links locale socials"
      ;
    }
  }

  &__logo {
    grid-area: logo;
  }

  &__list-item {
    font-size: var(--text-sm);

    &:not(:last-child) {
      margin-bottom: var(--space-sm);
    }

    &--title {
      font-weight: var(--font-bold);
    }
  }

  &__bua-links {
    grid-area: bua-links;
  }

  &__help-links {
    grid-area: help-links;
  }

  &__activity-links {
    grid-area: activity-links;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: var(--space-md);

    .app-footer__list-item--title {
      grid-column: 1 / -1;
    }

    @media screen and ($desktop) {
      grid-column-gap: var(--space-xl);
    }
  }

  &__separator {
    grid-area: separator;
    height: 1px;
    margin: var(--space-base) 0;
    border: none;
    background-color: var(--color-grey-empty);
  }

  &__trademark {
    grid-area: trademark;
    font-weight: var(--font-bold);
  }

  &__legal-links {
    grid-area: legal-links;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and ($desktop) {
      display: flex;
      align-items: center;
    }
  }

  &__legal-links-item {
    font-size: var(--text-sm);
    font-weight: var(--font-light);

    &:not(:last-child) {
      margin-right: var(--space-md);
    }
  }

  &__socials-list {
    grid-area: socials;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__socials-item {
    a {
      display: flex;
      align-items: center;
    }
  }

  &__locale {
    grid-area: locale;
    justify-self: center;

    @media screen and ($desktop) {
      justify-self: initial;
    }
  }
}

</style>