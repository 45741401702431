<template>
  <header class="app-header__wrapper">
    <div class="app-header__content">
      <router-link :to="{ name: 'LandingPage' }">
        <Logo :size="'sm'"></Logo>
      </router-link>

      <template v-if="$mediaQueries.isDesktop">
        <transition
          name="horizontal-slide-back-left-right"
          mode="out-in">
          <Icon
            v-if="!isArtist && !isDisplaySearchBar && !isSearchBarOpenByUser"
            @click="isSearchBarOpenByUser = true"
            :variant="'search-outline'"
            :size="'xs'"
            :backdropColor="'light'"
            :tag="'button'"
            type="button"
            class="app-header__search">
          </Icon>
          <ClientSearchBar v-if="isSearchBarOpenByUser || isDisplaySearchBar"/>
        </transition>
      </template>

      <ul class="app-header__actions-list">
        <li
          v-if="!$mediaQueries.isDesktop && !isArtist"
          class="app-header__item">
          <Icon
            @click="handleSearchClick"
            :tag="'button'"
            :variant="'search-outline'"
            :size="'sm'"
            class="mx-xs">
          </Icon>
        </li>
        <template v-if="isUserAuthenticated">
          <template v-if="$mediaQueries.isDesktop">
            <li
              v-if="isSearchBarOpenByUser"
              @click="isSearchBarOpenByUser = false"
              class="app-header__item">
              <Heading
                :level="'h4'"
                :tag="'button'"
                type="button"
                class="mx-md">
                <Icon
                  :variant="'arrow-left-outline'"
                  :size="'xs'"
                  :backdropColor="'light'"
                  class="mr-lg">
                </Icon>
                Menu
              </Heading>
            </li>
            <template v-if="!isDisplaySearchBar && !isSearchBarOpenByUser">
              <li
                v-for="(navItem, index) in navItems"
                :key="`app-header-item-${index}`"
                class="
                  app-header__item
                  app-header__item--nav
                ">
                <router-link
                  :to="{ name: navItem.link }"
                  active-class="app-header__item--active">
                  {{ navItem.label }}
                </router-link>
              </li>
            </template>
          </template>
          <li class="app-header__item">
            <div
              @click.capture.stop="isHeaderMenuOpen = !isHeaderMenuOpen"
              class="app-header__menu">
              <Avatar
                :isLoading="isLoadingProfilePicture"
                :imageSrc="croppedProfilePictureURL"
                :size="'xs'">
              </Avatar>
              <Icon
                :variant="'chevron-bottom-outline'"
                :size="'xs'"
                :tag="'button'"
                type="button"
                class="mx-sm">
              </Icon>
            </div>
          </li>
        </template>
        <template v-else>
          <li class="app-header__item">
            <router-link :to="{ name: ($route.name === 'ArtistProfilePublic') || $route.path.includes('/client')
              ? 'ClientSignin'
              : 'ArtistSignin'
            }">
              <Button isGhost>
                {{ $t('common.connexion') }}
              </Button>
            </router-link>
          </li>
          <li class="app-header__item">
            <router-link :to="{ name: ($route.name === 'ArtistProfilePublic') || $route.path.includes('/client')
              ? 'ClientSignup'
              : 'ArtistSignup'
            }">
              <Button hasNoBorder>
                {{ $t('common.signup') }}
              </Button>
            </router-link>
          </li>
        </template>
      </ul>

      <transition name="vertical-slide-up-down">
        <HeaderMenu
          v-if="isHeaderMenuOpen"
          @action="isHeaderMenuOpen = false"
          @close="isHeaderMenuOpen = false"
          :isArtist="isArtist">
        </HeaderMenu>
      </transition>
    </div>
  </header>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
}                                   from 'vuex';

import Avatar                       from '../../atoms/Avatar/a-Avatar.vue';
import Logo                         from '../../atoms/Logo/a-Logo.vue';
import Icon                         from '../../atoms/Icon/a-Icon.vue';
import Heading                      from '../../atoms/Heading/a-Heading.vue';
import Button                       from '../../atoms/Button/a-Button.vue';
import HeaderMenu                   from '../../molecules/HeaderMenu/m-HeaderMenu.vue';
import ClientSearchBar              from '../../../features/ClientDashboard/components/o-ClientSearchBar.vue';
import { getCroppedPictureURL }     from '../../../utils/URLUtils.js';


export default {
  name: 'm-AppHeader',
  components: {
    Avatar,
    Logo,
    Icon,
    Heading,
    Button,
    HeaderMenu,
    ClientSearchBar,
  },
  data: () => ({
    isHeaderMenuOpen: false,
    isSearchBarOpenByUser: false,
  }),
  computed: {
    ...mapState('User', ['user', 'isUserAuthenticated', 'isLoadingProfilePicture']),
    ...mapGetters({ isArtist: 'User/IS_ARTIST_USER' }),
    artistItems() {
      return [
        {
          label: this.$t('artist.dashboard.nav.myProfile'),
          link: 'ArtistProfile',
        },
        {
          label: this.$t('artist.dashboard.nav.myBookings'),
          link: 'ArtistDashboardBookings',
        },
      ];
    },
    clientItems() {
      return [
        {
          label: this.$t('artist.dashboard.nav.myBookings'),
          link: 'ClientBookings',
        },
      ];
    },
    navItems() {
      return this.isArtist ? this.artistItems : this.clientItems;
    },
    isDisplaySearchBar() {
      return this.$mediaQueries.isDesktop && this.$route.meta.isSearchBar;
    },
    croppedProfilePictureURL() {
      const { URLProfilePictures, ProfilePicturesCropsOptions } = this.user;

      return this.getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions, {}, { c: 'fill', w: 100, h: 100, });
    },
  },
  methods: {
    getCroppedPictureURL,
    ...mapMutations({
      toggleMobileFiltersType: 'Layout/TOGGLE_MOBILE_FILTERS_TYPE',
    }),
    handleSearchClick() {
      this.toggleMobileFiltersType('primary');
      this.$router.push({ name: 'ClientDashboard' });
    },
  },
}

</script>

<style lang="scss">

.app-header {
  &__wrapper {
    z-index: 100;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--color-white);
    box-shadow:
      0 0 2px 2px hsla(0, 0%, 0%, .025),
      0 0 8px 10px hsla(0, 0%, 0%, .015),
    ;

    &:before {
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      background: var(--color-gradient-main);
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1 0 auto;
    height: var(--space-header-height);
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--space-base) var(--space-md);
  }

  &__search {
    position: relative;
    top: 2px;
    margin-right: auto;
    margin-left: var(--space-xl);
  }

  &__actions-list {
    display: flex;
    align-items: center;
    align-self: stretch;
    margin-left: auto;
    flex-shrink: 0;
    grid-column-gap: var(--space-sm);

    a {
      display: flex;
      align-items: center;
    }

    @media screen and ($desktop) {
      grid-column-gap: var(--space-md);
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-self: center;
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    line-height: var(--lineheight-md);

    &--nav {
      align-self: stretch;
    }

    &--active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 100%;
        background: var(--color-gradient-main);
        transform: translateY(calc(var(--space-base) + -50% + 1.5px));
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    padding: var(--space-xxs);
    border-radius: var(--rounded-lg);
    background-color: var(--color-grey-light);
    cursor: pointer;
  }

  &__avatar-wrapper {
    background-color: var(--color-white);
  }
}

</style>
