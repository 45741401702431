<template>
  <ul
    ref="menu"
    class="header-menu__actions-list">
    <li
      v-for="(action, index) in actions"
      :key="`action-${index}`"
      class="header-menu__actions-item">
      <button
        @click="handleAction(action)"
        type="button">
        {{ action.label }}
      </button>
    </li>
  </ul>
</template>

<script>

import {
  mapActions,
  mapMutations,
}                   from 'vuex';


export default {
  name: 'm-HeaderMenu',
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actions() {
      return this.isArtist ? this.artistActions : this.clientActions;
    },
    clientActions() {
      return [
        {
          label: this.$t('common.dashboard'),
          type: 'SETTINGS',
          link: 'ClientDashboardSettings'
        },
        {
          label: this.$t('artist.dashboard.nav.myBookings'),
          type: 'BOOKINGS',
          link: 'ClientBookings'
        },
        {
          label: this.$t('artist.dashboard.menu.help'),
          type: 'HELP',
          link: 'ServiceFAQ'
        },
        {
          label: this.$t('artist.dashboard.menu.logout'),
          type: 'LOGOUT',
          link: 'LandingPage'
        },
      ];
    },
    artistActions() {
      return [
        ...(this.$mediaQueries.isDesktop
          ? []
          : [
              {
                label: this.$t('artist.dashboard.nav.myProfile'),
                type: 'PROFILE',
                link: 'ArtistProfile'
              },
              {
                label: this.$t('artist.dashboard.nav.myBookings'),
                type: 'BOOKINGS',
                link: 'ArtistDashboardBookings'
              },
            ]
        ),
        {
          label: this.$t('artist.dashboard.menu.artistIdentity'),
          type: 'SETTINGS',
          link: 'ArtistDashboard'
        },
        {
          label: this.$t('artist.dashboard.menu.payments'),
          type: 'PAYMENTS',
          link: 'ArtistPayments'
        },
        {
          label: this.$t('artist.dashboard.menu.help'),
          type: 'HELP',
          link: 'ServiceFAQ'
        },
        {
          label: this.$t('artist.dashboard.menu.logout'),
          type: 'LOGOUT',
          link: 'LandingPage',
        },
      ];
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapMutations({
      logout: 'User/LOG_OUT',
      clearSearchFilters: 'SearchArtist/CLEAR_SEARCH',
    }),
    ...mapActions({
      resetStore: 'RESET_STORE',
    }),
    async handleAction({ type, link }) {
      await this.$router.push({ name: link });

      if (type === 'LOGOUT') {
        !this.isArtist && this.clearSearchFilters();
        this.logout();
        this.resetStore();
      }

      this.$emit('close');
    },
    handleClickOutside(event) {
      const shouldCloseMenu = (event.target === window)
        || (event.target.id === 'app')
        || (event.target?.className?.includes?.('app-header'))
        || !event.target.contains(this.$refs.menu);

      if (shouldCloseMenu) this.$emit('action');
    }
  },
}

</script>

<style lang="scss">

.header-menu {
  &__actions-list {
    position: absolute;
    top: calc(var(--space-header-height) + var(--space-base));
    right: var(--space-sm);
    padding: var(--space-md) var(--space-lg);
    padding-right: var(--space-xl);
    padding-bottom: var(--space-lg);
    font-family: var(--font-stack-secondary);
    border-radius: var( --rounded-xl);
    background-color: var( --color-white);
    box-shadow: 0 0 20px hsla(0, 0%, 0%, .1);

    @media screen and ($desktop) {
      right: 0;
      padding-right: var(--space-xxl);
    }
  }

  &__actions-item {
    button {
      font-size: var(--text-sm);
      font-weight: var(--font-light);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-sm);
    }
  }
}

</style>
