<template>
  <div :class="[wrapperStyle, 'avatar__wrapper']">
    <Loader v-if="isLoading" :size="size"></Loader>

    <template v-else-if="imageSrc">
      <img
        v-on="$listeners"
        :src="imageSrc"
        :class="[avatarStyle, 'avatar__image']"
        alt="avatar"
        loading="lazy"/>
      <Icon
        v-if="isCertified"
        :variant="'certified-solid'"
        :color="'primary'"
        :size="certifiedIconSize"
        class="avatar__check">
      </Icon>
      <div class="avatar__actions">
        <slot name="actions"></slot>
      </div>
    </template>

    <slot
      v-else
      name="empty">
      <Icon
        :variant="'user-solid'"
        :size="size === 'xxl' ? 'xl' : size">
      </Icon>
    </slot>
  </div>
</template>

<script>

import Icon       from '../Icon/a-Icon.vue';
import Loader     from '../Loader/a-Loader.vue';


export default {
  name: 'a-Avatar',
  components: {
    Icon,
    Loader,
  },
  props: {
    imageSrc: {
      type: [String, URL],
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator : size => ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(size)
    },
    isCertified: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasWhiteBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wrapperStyle() {
      return {
        [`avatar__wrapper--${this.size}`]: true,
        'avatar__wrapper--white-border': !this.imageSrc && this.hasWhiteBorder
      };
    },
    avatarStyle() {
      return { 'avatar__image--white-border': this.hasWhiteBorder };
    },
    certifiedIconSize() {
      return ['xs', 'sm', 'md'].includes(this.size) ? 'xs' : this.size==='xxl'? 'lg':'md'
    }
  }
}

</script>

<style lang="scss">

.avatar {
  &__wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-light);
    border-radius: var(--rounded-circle);

    $sizes: (
      xs: 32,
      sm: 40,
      md: 64,
      lg: 80,
      xl: 96,
      xxl: 140,
    );

    @each $size-prop, $size-value in $sizes {
      &--#{$size-prop} {
        height: #{$size-value}px;
        width: #{$size-value}px;
      }
    }

    &--white-border {
      border: 4px solid var(--color-white);
    }
  }

  &__image {
    border-radius: var(--rounded-circle);
    height: 100%;
    width: 100%;
    object-fit: cover;

    &--white-border {
      border: 4px solid var(--color-white);
    }
  }

  &__check {
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 80%;
    transform: translateX(-20%);
  }

  &__actions {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    align-items: center;
    transform: translate(-50%, 35%);
  }
}

</style>
